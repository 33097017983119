@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);
html {
    transition: background-color 1s;
}

.container {
    height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.wordbox {
    border-radius: 15px;
    text-align: right;
    max-width: 600px;
    margin: auto;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

@media (max-width: 768px) {
    .wordbox {
        max-width: 90%;
        margin-top: 30%;
    }
}

#language {
    margin: 30px;
    text-align: left;
    font-family: "Anonymous Pro", bold;
}

#word {
    margin: 30px;
    text-align: left;
    font-family: "Anonymous Pro", monospace;
}

.fadeIn {
    opacity: 0;
    display: block;
    animation: fadeIn 1s ease-out forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#meaning {
    margin: 30px;
    font-family: "Anonymous Pro", italic;
}

.newword {
    margin: 30px;
    position: relative;
    border-radius: 7px;
    border: 0;
    height: 35px;
    width: 100px;
    font-weight: 700;
    font-family: "Anonymous Pro", monospace;
    color: white;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

#dedication {
    text-align: center;
    color: white;
    font-family: "Anonymous Pro", sans-serif;
}